import React from "react";
import "./Blogs.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/css/autoplay";
import "swiper/css/navigation";

import blog1 from "../../../../AsstsforBlogs/blogs1.jpg";
import blog2 from "../../../../AsstsforBlogs/blogs2.jpg";
import blog3 from "../../../../AsstsforBlogs/blogs3.jpg";
import blog4 from "../../../../AsstsforBlogs/blogs4.jpg";
import blog5 from "../../../../AsstsforBlogs/blogs5.jpg";
import blog6 from "../../../../AsstsforBlogs/blogs6.jpg";
import blog7 from "../../../../AsstsforBlogs/blog7.jpg";
import blog8 from "../../../../AsstsforBlogs/blog8.jpg";

import Footerlower from "../../../Footerlower/LowerFooter";
import Offcanvas from "../../../Offcanvas/Offcanvas";
import { Link } from "react-router-dom";
import KNSLogo from "../../../KNSLogo/KNSLogo";
import { Helmet } from "react-helmet";

const Blogs = () => {
  return (
    <div>
      <Helmet>
        <title>
          RealEstate Blogs | Most trusted plotted developers in Bangalore
        </title>
        <meta
          name="description"
          content="Stay informed with real estate blogs from the most trusted plotted
developers in Bangalore. Get the latest insights, trends, and investment tips"
        />
        <link rel="canonical" href="https://www.knsgroup.in/blogs" />
      </Helmet>

      <Offcanvas></Offcanvas>
      <KNSLogo></KNSLogo>
      <div className="container my-5">
        <h2 className="text-center margin-kick mb-2">Blogs</h2>
        <div className="row ">
          <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            navigation
            autoplay={{ delay: 3000 }}
            breakpoints={{
              0: {
                spaceBetween: 10,
                slidesPerView: 1,
              },
              480: {
                spaceBetween: 10,
                slidesPerView: 2,
              },
              768: {
                spaceBetween: 15,
                slidesPerView: 2,
              },
              1024: {
                spaceBetween: 30,
                slidesPerView: 4,
              },
            }}
            className="image-slider text-center"

            // pagination={{ clickable: true }}
          >
            <SwiperSlide className="p-3">
              {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
              <Link to="/blogs/blogEight">
                <div className="text-center mb-5 shadow ">
                  <img src={blog8} class="w-100  " alt="blog image" />
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="p-3">
              {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
              <Link to="/blogs/blogSeven">
                <div className="text-center mb-5 shadow ">
                  <img src={blog7} class="w-100  " alt="blog image" />
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="p-3">
              {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
              <Link to="/blogs/blogone">
                <div className="text-center mb-5 shadow ">
                  <img src={blog1} class="w-100  " alt="blog image" />
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="p-3">
              {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
              <Link to="/blogs/blogtwo">
                <div className="text-center mb-5 shadow ">
                  <img src={blog2} class="w-100  " alt="blog image" />
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="p-3">
              {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
              <Link to="/blogs/blogthree">
                <div className="text-center mb-5 shadow ">
                  <img src={blog3} class="w-100  " alt="blog image" />
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="p-3">
              {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
              <Link to="/blogs/blogfour">
                <div className="text-center mb-5 shadow ">
                  <img src={blog4} class="w-100 " alt="blog image" />
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="p-3">
              {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
              <Link to="/blogs/blogfive">
                <div className="text-center mb-5 shadow ">
                  <img src={blog5} class="w-100  " alt="blog image" />
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="p-3">
              {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
              <Link to="/blogs/blogsix">
                <div className="text-center mb-5 shadow ">
                  <img src={blog6} class="w-100" alt="blog image" />
                </div>
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <Footerlower></Footerlower>
    </div>
  );
};

export default Blogs;
