import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";

import Offcanvas from "../../../../../Offcanvas/Offcanvas";
import KNSLogo from "../../../../../KNSLogo/KNSLogo";
import Faqcalisto from "../../../Mysoreprojects/Allprojectmysore/Calisto/Faq/Faqcalisto";
import Footerlower from "../../../../../Footerlower/LowerFooter";
import CandrillDetail from "./logo-candril.webp";
// import CandrilMainImage from "../../../../../../AssetsforProjects/athenaumg.webp";
// import AloraLocation from './AloraLocation/AloraLocation';
// import AloraSlider from './AloraSlider/AloraSlider';
import CandrilMainImage from "./heroImage.png";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CandrillLocation from "./CandrillLocation/CandrillLocation";
import marketingIcon from "./assets/UniternLogo.webp";
// import { Modal } from 'bootstrap';

const Aadrika = () => {
  // const modalRef = useRef(null);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     showModal();
  //   }, 8000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  // const showModal = () => {
  //   const modalElement = modalRef.current;
  //   const modal = new Modal(modalElement);
  //   modal.show();
  // }

  const navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    if (formData.name && formData.email && formData.number) {
      // emailjs.sendForm('service_z652qps', 'template_prp5ovi', e.target, 'BrVdjJ_dP2sCEqW45')
      emailjs
        .sendForm(
          "service_v73c39b",
          "template_5e93gnf",
          e.target,
          "Q_tnUooVuTOT2eItg"
        )
        .then(
          (result) => {
            // alert('Thank You! We reach you soon!');
            // Swal.fire(
            //     'Thank You!',
            //     'We will reach you soon',
            //     'success'
            //   );
            // navigate('https://thankyoupage.knsgroup.in/');
            window.location.href = "https://thankyoupage.knsgroup.in/";

            setFormData({
              name: "",
              email: "",
              number: "",
            });
          },
          (error) => {
            // alert('Server Issus! Not Submitted');
            Swal.fire("Oops!", "Somethings Wrong, Please Try Again", "error");
            setFormData({
              name: "",
              email: "",
              number: "",
            });
          }
        );
      e.target.reset();
    } else {
      Swal.fire("Oops!", "Please Fill the details", "info");
    }
  };
  return (
    <div>
      <Helmet>
        <title>KNS Candrill | Your Dream Home Awaits in Doddaballapura</title>

        <meta
          name="description"
          content="Experience the best of both worlds: the convenience of city living and the serenity of nature. KNS Candrill offers affordable plots in Doddaballapura."
        />
        <link rel="canonical" href="https://knsgroup.in/aadrika" />
      </Helmet>

      <Offcanvas></Offcanvas>
      <KNSLogo></KNSLogo>
      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-6 col-sm-12 order-last order-lg-first d-flex flex-column">
            <div className="d-flex flex-column justify-content-center ">
              <div className="text-center mt-5">
                <img
                  src={CandrillDetail}
                  className="w-25 top-mar mb-5"
                  alt="arohalogo"
                />
              </div>
              <div>
                <p className="fw-light ps-5 pe-5 py-2 mb-5 font-sizer text-center">
                  KNS Candrill offers more than just a home—it offers a
                  connection to a locale steeped in history and brimming with
                  potential. Doddaballapur, with its rich tapestry of myths,
                  legends, and historical significance, is older than Bengaluru
                  itself. This town, once central to stories of cultural and
                  historical significance, is now emerging as a key player in
                  Bengaluru’s future, blending tradition with modernity
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 order-first order-lg-last d-flex align-items-center">
            <img
              className="w-100 margin-imager"
              src={CandrilMainImage}
              alt="atharvaimagedetails"
            />
          </div>
        </div>
      </div>

      {/* 
      <div className="container p-3">
        <form class="row g-3" onSubmit={sendEmail}>
          <div class="col-md-3 col-sm-12">
            <label for="inputEmail4" class="form-label">
              Project Name
            </label>
            <input
              type="text"
              class="form-control"
              id="inputEmail4"
              name="project_name"
              value="KNS ATHENA PHASE II"
            />
          </div>
          <div class="col-md-3 col-sm-12">
            <label for="inputEmail4" class="form-label">
              Name
            </label>
            <input
              type="text"
              class="form-control"
              id="inputEmail4"
              name="name"
              value={formData.name}
              onChange={(event) =>
                setFormData({ ...formData, name: event.target.value })
              }
            />
          </div>
          <div class="col-md-3 col-sm-12">
            <label for="inputPassword4" class="form-label">
              Contact Number
            </label>
            <input
              type="number"
              class="form-control hide-number-arrow"
              id="inputPassword4"
              name="number"
              value={formData.number}
              onChange={(event) =>
                setFormData({ ...formData, number: event.target.value })
              }
            />
          </div>
          <div class="col-md-3 col-sm-12">
            <label for="inputPassword4" class="form-label">
              Email
            </label>
            <input
              type="email"
              class="form-control"
              id="inputPassword4"
              name="user_email"
              value={formData.email}
              onChange={(event) =>
                setFormData({ ...formData, email: event.target.value })
              }
            />
          </div>
          <div className="col-6">
            <button
              type="submit"
              class="btn button-color my-5"
              disabled={isButtonDisabled}
            >
              Submit
            </button>
          </div>
        </form>
      </div> */}

      <CandrillLocation></CandrillLocation>
      <div className="bg-dark text-white">
        {/* <h5 className="fw-light ps-5 pe-5 py-2 mb-5  text-center">
          Makerting By :- Unitern
          <br />
        </h5> */}
      </div>
      <div className="d-flex justify-content-center align-items-center p-3">
        <img src={marketingIcon} alt="icons" className="img-fluid" />
      </div>

      <Faqcalisto></Faqcalisto>
      <Footerlower></Footerlower>
    </div>
  );
};

export default Aadrika;
