import React, { useState } from "react";
import "./AllprojectMysuru.css";
import imgback from "../../../../../AssetsforMysuruproject/mysurularge.jpg";
import imgmob from "../../../../../AssetsforMysuruproject/mysurumob.jpg";
import newlaunch from "../../../../../AssetsforProjects/newlaunch.gif";

import ethos from "../../../../../AssetsforMysuruproject/ethosimg.jpg";
import ethosdetails from "../../../../../AssetsforMysuruproject/ethoshover.jpg";
import ethosmob from "../../../../../AssetsforMysuruproject/ethosmob.jpg";

import calisto from "../../../../../AssetsforMysuruproject/calistoimg.jpg";
import calistodetails from "../../../../../AssetsforMysuruproject/calistohover.jpg";
import calistomob from "../../../../../AssetsforMysuruproject/calistomob.jpg";

import logoethos from "../../../../../AssetsforMysuruproject/logoethos.jpg";
import logocalisto from "../../../../../AssetsforMysuruproject/logocalisto.jpg";

import { Link } from "react-router-dom";
import Footerlower from "../../../../Footerlower/LowerFooter";
import Offcanvas from "../../../../Offcanvas/Offcanvas";
import KNSLogo from "../../../../KNSLogo/KNSLogo";
import { Helmet } from "react-helmet";

const AllprojectMysuru = () => {
  const [ethosHovered, setethosHovered] = useState(false);
  const [calistoHovered, setcalistoHovered] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Helmet>
        <title>
          Site & villa plots for sale in Mysore | KNS Infrastructure
        </title>
        <meta
          name="description"
          content="Discover prime site and villa plots for sale in Mysore with KNS Infrastructure.
Ideal for your next home or investment project. Learn more now!"
        />
        <link rel="canonical" href="https://knsgroup.in/allprojectsmysuru" />
      </Helmet>

      <Offcanvas></Offcanvas>
      <KNSLogo></KNSLogo>
      <div>
        <div>
          <img
            src={imgback}
            className="w-100 h-100 img-view"
            alt="background project page"
          />
          <img
            src={imgmob}
            className="w-100 h-100 img-viewmob"
            alt="background project page"
          />
        </div>

        <div className="container d-flex justify-content-center">
          <div className="row w-r">
            <div className="col-lg-6 col-md-12 col-sm-12 mt-5">
              <div className="text-center d-flex flex-column p-5 position-relative ">
                <img
                  src={calistoHovered ? calistodetails : calisto}
                  onMouseEnter={() => setcalistoHovered(true)}
                  onMouseLeave={() => setcalistoHovered(false)}
                  class=" image-size displayimg px-2 "
                  alt="Arohaimage "
                />
                <img
                  src={calistomob}
                  className="mobimg px-2 w-100"
                  alt="mobileviewimage"
                />
                <img
                  src={newlaunch}
                  class=" position-absolute top-0 start-0 w-50 p-5 ms-2"
                  alt="newlaunch-gif"
                />
                <img src={logocalisto} class=" w-100 p-2 mb-2" alt="..." />
                <div className="text-center mt-3" onClick={scrollToTop}>
                  <Link className="button-color w-25 " to="/calisto">
                    Know More
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 mt-5">
              <div className="text-center  flex-column p-5 ">
                <img
                  src={ethosHovered ? ethosdetails : ethos}
                  onMouseEnter={() => setethosHovered(true)}
                  onMouseLeave={() => setethosHovered(false)}
                  class=" image-size displayimg px-2 "
                  alt="Arohaimage "
                />
                <img
                  src={ethosmob}
                  className="mobimg w-100 px-2"
                  alt="mobileviewimage"
                />
                <img src={logoethos} class=" w-100 p-2 mb-2" alt="logo" />
                <div className="text-center mt-3" onClick={scrollToTop}>
                  <Link className="button-color w-25 " to="/ethos">
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footerlower></Footerlower>
    </div>
  );
};

export default AllprojectMysuru;
