import React from "react";
// import icon from "../../Assets/menu.png";
import knslogo from "../../Assets/13.png";
import "./Offcanvas.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
const Offcanvas = () => {
  return (
    <div className="">
      {/* offcanvas here */}
      <button
        className="btn btn-none z-3 position-fixed top-0 start-0"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling"
        aria-controls="offcanvasScrolling "
      >
        {/* <img className="icon-margin" src={icon} />                 */}
        <p className="text-setup  mt-2 ms-1 fw-dark ">
          <FontAwesomeIcon
            icon={faBars}
            size="xl"
            style={{ color: "#4a6579" }}
          />
        </p>
      </button>
      <div
        className="offcanvas offcanvas-start"
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabindex="-1"
        id="offcanvasScrolling"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div className="offcanvas-header ">
          <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
            <img className="knslogo-width" src={knslogo} alt="kns logo" />
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul class="nav flex-column">
            <li class="nav-item">
              <Link class="nav-link py-2 ps-3 my-2 ms-3 text-decoration" to="/">
                HOME
              </Link>
            </li>
            <li class="nav-item">
              <Link
                class="nav-link py-2 ps-3 my-2 ms-3 text-decoration"
                to="/about"
              >
                ABOUT
              </Link>
            </li>
            <li class="nav-item">
              <Link
                class="nav-link py-2 ps-3 my-2 ms-3 text-decoration"
                to="/projects"
              >
                PROJECTS
              </Link>
            </li>
            <li class="nav-item">
              <Link
                class="nav-link py-2 ps-3 my-2 ms-3 text-decoration"
                to="/gallery"
              >
                GALLERY
              </Link>
            </li>
            <li class="nav-item">
              <Link
                class="nav-link py-2 ps-3 my-2 ms-3 text-decoration"
                to="/undermaintenance"
              >
                CAREERS
              </Link>
            </li>
            <li class="nav-item">
              <Link
                class="nav-link py-2 ps-3 my-2  ms-3 text-decoration"
                to="/blogs"
              >
                BLOGS
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Offcanvas;
