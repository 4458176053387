import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Aboutuspage from "./Component/Home/Aboutus/Aboutuspage/Aboutuspage";
import BlogEight from "./Component/Home/Aboutus/Blogs/BlogsEight/BlogEight";
import Blogseven from "./Component/Home/Aboutus/Blogs/Blogseven/Blogseven";
import Blogfive from "./Component/Home/Aboutus/Blogs/Blogfive/Blogfive";
import Blogfour from "./Component/Home/Aboutus/Blogs/Blogfour/Blogfour";
import Blogs from "./Component/Home/Aboutus/Blogs/Blogs";
import Blogsix from "./Component/Home/Aboutus/Blogs/Blogsix/Blogsix";
import Blogone from "./Component/Home/Aboutus/Blogs/Blogsone/Blogone";
import Blogthree from "./Component/Home/Aboutus/Blogs/Blogthree/Blogthree";
import Blogtwo from "./Component/Home/Aboutus/Blogs/Blogtwo/Blogtwo";
import Gallary from "./Component/Home/Gallary/Gallary";
import Openhouse from "./Component/Home/Gallary/Openhouse/Openhouse";
import Homeone from "./Component/Home/Homeone";
import Allprojectben from "./Component/Home/Projects/Bangaloreprojects/Allprojectben";
import Aroha from "./Component/Home/Projects/Bangaloreprojects/AllprojectsBangaluru/Aroha/Aroha";
import AthenaTwo from "./Component/Home/Projects/Bangaloreprojects/AllprojectsBangaluru/AthenaTwo/AthenaTwo";
import Atharva from "./Component/Home/Projects/Bangaloreprojects/AllprojectsBangaluru/Atharva/Atharva";
import Fresco from "./Component/Home/Projects/Bangaloreprojects/AllprojectsBangaluru/Fesco/Fresco";
import Candrill from "./Component/Home/Projects/Bangaloreprojects/AllprojectsBangaluru/Candrill/Candrill";

// import Location from './Component/Home/Projects/Location';
import AllprojectMysuru from "./Component/Home/Projects/Mysoreprojects/Allprojectmysore/AllprojectMysuru";
import Calistomain from "./Component/Home/Projects/Mysoreprojects/Allprojectmysore/Calisto/Calistomain";
import Ethos from "./Component/Home/Projects/Mysoreprojects/Allprojectmysore/Ethos/Ethos";
import Dis from "./Component/DisPrivecy/Dis";
import Pri from "./Component/DisPrivecy/Pri";
import Undermain from "./Component/UmderMain/Undermain";
import ProjectsWeb from "./Component/Home/Projects/ProjectsWeb";

import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Alora from "./Component/Home/Projects/Bangaloreprojects/AllprojectsBangaluru/Alora/Alora";
import Amora from "./Component/Home/Projects/Bangaloreprojects/AllprojectsBangaluru/Amora/Amora";
import Thankyou from "./Component/Thankyou/Thankyou";
import Aadrika from "./Component/Home/Projects/Bangaloreprojects/AllprojectsBangaluru/Aadrika/Aadrika";
import Laurel from "./Component/Home/Projects/Bangaloreprojects/AllprojectsBangaluru/Laurel/Laurel";

const TrakingID = "G-G55B9N6M6X";
ReactGA.initialize(TrakingID);

function App() {
  const router = createBrowserRouter([
    { path: "/", element: <Homeone></Homeone> },
    { path: "*", element: <Homeone></Homeone> },
    { path: "/home", element: <Homeone></Homeone> },
    { path: "/allprojectsbengaluru", element: <Allprojectben></Allprojectben> },

    { path: "/gallery", element: <Gallary></Gallary> },
    { path: "/gallery/openhouse", element: <Openhouse></Openhouse> },
    { path: "/about", element: <Aboutuspage></Aboutuspage> },
    { path: "/projects", element: <ProjectsWeb></ProjectsWeb> },

    {
      path: "/allprojectsmysuru",
      element: <AllprojectMysuru></AllprojectMysuru>,
    },

    // blogs all pages here
    { path: "/blogs", element: <Blogs></Blogs> },
    { path: "/blogs/blogone", element: <Blogone></Blogone> },
    { path: "/blogs/blogtwo", element: <Blogtwo></Blogtwo> },
    { path: "/blogs/blogthree", element: <Blogthree></Blogthree> },
    { path: "/blogs/blogfour", element: <Blogfour></Blogfour> },
    { path: "/blogs/blogfive", element: <Blogfive></Blogfive> },
    { path: "/blogs/blogsix", element: <Blogsix></Blogsix> },
    { path: "/blogs/blogseven", element: <Blogseven></Blogseven> },
    { path: "/blogs/blogEight", element: <BlogEight></BlogEight> },

    // individual project page
    { path: "/calisto", element: <Calistomain></Calistomain> },
    { path: "/atharva", element: <Atharva></Atharva> },
    { path: "/fresco", element: <Fresco></Fresco> },
    { path: "/aroha", element: <Aroha></Aroha> },
    { path: "/ethos", element: <Ethos></Ethos> },
    { path: "/alora", element: <Alora></Alora> },
    { path: "/aadrika", element: <Aadrika></Aadrika> },
    { path: "/athena2", element: <AthenaTwo></AthenaTwo> },
    { path: "/laurel", element: <Laurel></Laurel> },
    { path: "/amora", element: <Amora></Amora> },
    { path: "/Candrill", element: <Candrill></Candrill> },

    // {path:'/billore', element: <Billore></Billore>},

    //dis & pry
    { path: "/disclaimer", element: <Dis></Dis> },
    { path: "/privacy", element: <Pri></Pri> },
    { path: "/thankyou", element: <Thankyou></Thankyou> },
    { path: "/undermaintenance", element: <Undermain></Undermain> },
  ]);

  return (
    <div className="App">
      <Helmet>
        <title>
          Sites and Plots for sale in Bangalore & Mysore | KNS Group
        </title>
        <meta
          name="description"
          content="Plots for Sale in Bangalore and Mysore | KNS Infrastructure offers BDA, BIAPPA, MUDA and BMRDA approved premium sites, villa & Gated Community plots"
        />
        <link rel="canonical" href="https://knsgroup.in" />
      </Helmet>

      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
