import React, { useState } from "react";
import "./Gallary.css";
// import galleryback from '../../../Assets/backgallery.jpg';
// import gallerymob from '../../../AssetsforGallery/mobgallery.jpg';
import Offcanvas from "../../Offcanvas/Offcanvas";
import Footerlower from "../../Footerlower/LowerFooter";
import { Link } from "react-router-dom";
import KNSLogo from "../../KNSLogo/KNSLogo";

import { Helmet } from "react-helmet";

const Gallary = () => {
  // const [hovered, setHovered] = useState(false);

  // const handleMouseEnter = () => {
  //   setHovered(true);

  // };

  // const handleMouseLeave = () => {
  //   setHovered(false);
  // };

  const [display, setDisplay] = useState(false);
  const [displayone, setDisplayone] = useState(false);
  const [displaytwo, setDisplaytwo] = useState(false);

  const handleMouseEnter = () => {
    setDisplay(true);
  };
  const handleMouseEnterOne = () => {
    setDisplayone(true);
  };

  const handleMouseLeaveOne = () => {
    setDisplayone(false);
  };

  const handleMouseEntertwo = () => {
    setDisplaytwo(true);
  };
  const handleMouseLeavetwo = () => {
    setDisplaytwo(false);
  };

  const handleMouseLeave = () => {
    setDisplay(false);
  };

  return (
    <div>
      <Helmet>
        <title>Luxury Sites and Plots Gallery | KNS Group </title>
        <meta
          name="description"
          content="View our collection of luxury sites and plots at KNS Group. Perfect for
building your dream home in a prestigious location. Explore now!"
        />
        <link rel="canonical" href="https://knsgroup.in/fresco" />
      </Helmet>

      <Offcanvas></Offcanvas>
      <KNSLogo></KNSLogo>

      <div className="backgrounder">
        <div className="container text-center ">
          <div className=" row position-setup  ">
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="col-lg-4 col-sm-12 mt-2  text-decorationone  text-center jadu  position-setup-1  "
            >
              <Link
                className="button nav-link p-3 m-3 font-sizes "
                to="/gallery"
              >
                Projects
              </Link>

              <p
                style={{ display: display ? "block" : "none" }}
                className="fw-light"
              >
                Photos of our <br />
                completed projects <br />
                in all its glory
              </p>
              <div
                style={{ display: display ? "block" : "none" }}
                className="d-flex justify-content-center "
              >
                <Link
                  style={{ display: display ? "block" : "none" }}
                  className="  button-color my-5"
                  to="/gallery/openhouse"
                >
                  Explore
                </Link>
              </div>
            </div>

            <div
              onMouseEnter={handleMouseEnterOne}
              onMouseLeave={handleMouseLeaveOne}
              className="col-lg-4 col-sm-12 mt-2 hight text-decorationone  text-center jadu "
            >
              <Link
                className="button nav-link p-3 m-3 font-sizes"
                to="/undermaintenance"
              >
                Quality
              </Link>

              <p
                style={{ display: displayone ? "block" : "none" }}
                className="fw-light "
              >
                Photos of our <br />
                quality standards <br />
                and working <br /> procedues
              </p>
              <div
                style={{ display: displayone ? "block" : "none" }}
                className="d-flex justify-content-center"
              >
                <Link
                  style={{ display: displayone ? "block" : "none" }}
                  className="button-color my-4"
                  to="/gallery"
                >
                  Explore
                </Link>
              </div>
            </div>

            <div
              onMouseEnter={handleMouseEntertwo}
              onMouseLeave={handleMouseLeavetwo}
              className="col-lg-4 col-sm-12 mt-2 hight text-decorationone  text-center jadu "
            >
              <Link
                className="button nav-link p-3 m-3 font-sizes"
                to="/undermaintenance"
              >
                Events
              </Link>

              <p
                style={{ display: displaytwo ? "block" : "none" }}
                className="fw-light my-4"
              >
                Life and Experiences
              </p>
              <div
                style={{ display: displaytwo ? "block" : "none" }}
                className="d-flex justify-content-center"
              >
                <Link
                  style={{ display: displaytwo ? "block" : "none" }}
                  className="button-color my-5"
                  to="/gallery"
                >
                  Explore
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <Footerlower></Footerlower>
      </div>
    </div>
  );
};

export default Gallary;
